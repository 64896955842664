/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Loading,
  ModalDefault,
  InputSearch,
  Input,
} from '~/components';
import frequencyService from '~/services/frequencyService';
import { generateDefaultInputValues, isEmpty, validateForm } from '~/utils';
import { usePageContext } from '~/hooks';
import updateService from '~/services/updateService';
import {
  Title,
  Text,
  Select,
  Question,
  Margin,
  ContainerClasses,
} from './styles';

interface IProps {
  onSave: () => void;
}

interface IPropsInput {
  isValid: boolean;
  value: '0' | '1';
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const ModalUpdate: React.FC<IProps> = ({ onSave }: IProps) => {
  const stateSchema = {
    ...generateDefaultInputValues([
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
    ]),
  };
  const [steps, setSteps] = useState(1);
  const [inputState, setInputState] = useState<typeInput>(stateSchema);
  const [listClasses, setListClasses] = useState<any[]>([]);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [loading, setLoading] = useState(true);
  const state: string[] = [];
  const { leader } = usePageContext();
  // const { leader, leaderId } = usePageContext();
  const [listLeaders, setListLeaders] = useState([]);
  const [currentLeadersName, setCurrentLeadersName] = useState('');
  const [inputLeaders, setInputLeaders] = useState('');
  const [, setLeaderDifferent] = useState(false);
  const [, setNewLeaderId] = useState(0);
  const [, setInitialDate] = useState('');
  const [, setFinalDate] = useState('');
  const [erroLeader] = useState({
    initialDate: false,
    finalDate: false,
    newLeaderId: false,
  });

  const handleInput = (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState![inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  useEffect(() => {
    const loadClasses = async () => {
      setLoading(true);
      try {
        const response = await frequencyService.listAllClasses();
        setListClasses(response.result);

        response.result.forEach((item: any) => {
          state.push(item.id);
        });
      } catch {
        setIsVisibleModalErro(true);
      }
    };

    const loadFunctions = async () => {
      await loadClasses();
    };
    loadFunctions();
  }, []);

  useEffect(() => {
    const loadLeaders = async () => {
      const response = await updateService.ListLeaders();
      setListLeaders(response.result);
    };
    const loadMyClasses = async () => {
      const response = await frequencyService.listMyClasses();

      response.forEach((allInput: any) => {
        handleInput('1', allInput.id);
      });
    };
    loadLeaders();
    loadMyClasses();
    setLoading(false);
  }, []);

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const validAll = async () => {
    const value: number[] = [];

    Object.entries(inputState).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    const create = async () => {
      // if (leaderDifferent) {
      //  setErroLeader({
      //    finalDate: finalDate.length === 0,
      //    initialDate: initialDate.length === 0,
      //    newLeaderId: newLeaderId === 0,
      //  });
      // }
      if (isFormValid()) {
        try {
          Object.entries(inputState).forEach(inputValue => {
            if (inputValue[1].value === '1') {
              value.push(parseFloat(inputValue[0]));
            }
          });
          const quarter = 1;
          const year = 2026;
          await updateService.CreateRequestUpdate({
            classesIds: value,
            quarter,
            year,
          });

          onSave();
        } catch {
          setIsVisibleModalErro(true);
        }
      } else {
        setIsVisibleModalErro(true);
      }
    };
    create();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Modal width="95%">
      <>
        {isVisibleModalErro && (
          <ModalDefault
            success={false}
            onClick={() => setIsVisibleModalErro(false)}
          >
            Ops... algo deu errado!
          </ModalDefault>
        )}
        {steps === 1 && (
          <>
            <Title>Atualizar Cadastro</Title>
            <Text>
              A paz do Senhor! A fim de manter o cadastro de salas de todas as
              igrejas atualizados,{' '}
              <b>
                solicitamos que você informe todas as salas em funcionamento na
                sua igreja.
              </b>
            </Text>
            <Text>
              Cadastro está sujeito a aceitação do administrador do sistema!
            </Text>
            <Text>
              <b>
                Só será possível acessar o sistema depois de inserir as
                informações solicitadas!
              </b>
            </Text>
            <Button onClick={() => setSteps(2)}>Confirmar</Button>
          </>
        )}
        {steps === 2 && (
          <ContainerClasses>
            <Title>Selecione as classes</Title>
            {listClasses.map(item => {
              return (
                <Question key={item.id}>
                  <b>{item.name}</b>
                  <Select
                    onChange={(e: any) => handleInput(e.target.value, item.id)}
                    value={inputState[item.id]?.value}
                  >
                    <option value="0">Não</option>
                    <option value="1">Sim</option>
                  </Select>
                </Question>
              );
            })}

            <Button onClick={() => setSteps(3)}>Salvar</Button>
            <Margin />
            <Button styles={1} onClick={() => setSteps(1)}>
              Voltar
            </Button>
          </ContainerClasses>
        )}
        {steps === 3 && (
          <>
            <Title>Confirme as classes</Title>
            {listClasses.map(item => {
              if (inputState[item.id]?.value === '1') {
                return (
                  <Question key={item.id}>
                    <b>{item.name}</b>
                  </Question>
                );
              }
              return null;
            })}
            <Button onClick={validAll}>Confirmar</Button>
            <Margin />
            <Button styles={1} onClick={() => setSteps(2)}>
              Voltar
            </Button>
          </>
        )}
        {steps === 4 && (
          <>
            <Title>Confirme o Dirigente</Title>
            <Title>
              <b>{leader}</b>
            </Title>
            <Button onClick={validAll}>Confirmar</Button>
            <Margin />
            <Button
              styles={1}
              onClick={() => {
                setSteps(5);
                setLeaderDifferent(true);
              }}
            >
              Informar Alteração
            </Button>
          </>
        )}

        {steps === 5 && (
          <>
            <Title>Alterar o Dirigente</Title>

            <Input
              label={`Data do ultimo culto de ${leader}`}
              type="date"
              onChange={e => setFinalDate(e.target.value)}
              error={erroLeader.finalDate ? 'Selecione uma data' : ''}
            />
            <InputSearch
              label="Dirigente da congregação"
              lista={listLeaders}
              valueInput={inputLeaders}
              currentValue={currentLeadersName}
              onChange={e => setInputLeaders(e.target.value)}
              error={
                erroLeader.newLeaderId ? 'Selecione um novo dirigente' : ''
              }
              onClickList={e => {
                setCurrentLeadersName(e.name);
                setInputLeaders(e.name);
                setNewLeaderId(e.id);
              }}
              requiredLabel
            />
            <Input
              type="date"
              onChange={e => setInitialDate(e.target.value)}
              label="Início"
              error={erroLeader.initialDate ? 'Selecione uma data' : ''}
            />
            <Button onClick={validAll}>Salvar</Button>
          </>
        )}
      </>
    </Modal>
  );
};

export default ModalUpdate;
