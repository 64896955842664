import React from 'react';
import { Switch } from 'react-router-dom';

import Home from '~/pages/Home';
import Frequency from '~/pages/Frequency';
import Login from '~/pages/Login';
import Reports from '~/pages/Reports';
import TeacherRegistration from '~/pages/TeacherRegistration';
import Teacher from '~/pages/RegisterTeacher';
import RedefinePassword from '~/pages/RedefinePassword';

import Route from './Route';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/home" exact component={Home} isPrivate />
    <Route path="/frequencia" exact component={Frequency} isPrivate />
    <Route path="/relatorios" exact component={Reports} isPrivate />
    <Route path="/senha" exact component={RedefinePassword} isPrivate />
    <Route path="/professores" exact component={Teacher} isPrivate />
    <Route path="/cadastro/:church_id" exact component={TeacherRegistration} />
    <Route
      path="*"
      component={() => <h1 className="text-center">Not Found 404</h1>}
    />
  </Switch>
);

export default Routes;
