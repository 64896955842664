import { api } from './api';

interface ILogin {
  email: string;
  password: string;
}

class AuthService {
  async login({ email, password }: ILogin): Promise<any> {
    const response = await api.post(`/bs/sessions`, {
      email,
      password,
    });

    return response.data;
  }

  async validateUpdate(): Promise<any> {
    const response = await api.get(
      `/bs/update-classes-request/year/2026/quarter/1`,
    );

    return response.data;
  }

  async getVersion(): Promise<any> {
    const response = await api.get(`/bs/version`);

    return response.data;
  }
}

export default new AuthService();
